const DATA = [
  {
    id: 1,
    name: 'Human-Aware Planning and Explainable AI',
    link: null,
    image: 'xai',
    primary: true,
    secondary: false,
    tertiary: false,
    tags: ['xai', 'humanai'],
  },
  {
    id: 2,
    name: 'Process Automation',
    link: null,
    image: 'process',
    primary: false,
    secondary: true,
    tertiary: false,
    tags: ['bpm'],
  },
  {
    id: 3,
    name: 'Mixed Reality',
    link: null,
    image: 'vamhri',
    primary: false,
    secondary: true,
    tertiary: false,
    tags: ['vamhri'],
  },
  {
    id: 5,
    name: 'AI4BPM @ AAAI',
    link: 'https://ai4bpm.com',
    image: null,
    primary: false,
    secondary: false,
    tertiary: true,
  },
  {
    id: 6,
    name: 'XAIP @ ICAPS',
    link: 'https://explainableplanning.com',
    image: null,
    primary: false,
    secondary: false,
    tertiary: true,
  },
  {
    id: 7,
    name: 'VAM-HRI @ HRI',
    link: 'http://vamhri.com',
    image: null,
    primary: false,
    secondary: false,
    tertiary: true,
  },
  {
    id: 8,
    name: 'Watson Orchestrate',
    link: 'https://www.ibm.com/cloud/automation/watson-orchestrate/',
    image: 'wo',
    primary: false,
    secondary: false,
    tertiary: true,
  },
];

export default DATA;
