const DATA = [
  'IBM 2023 Research Accomplishment on AI Planning Technology for IBM Applications',
  'IBM 2023 Corporate Technical Recognition for Open Source Significant Contributor Award',
  'ICAPS 2023 People’s Choice Best System Demonstration Award Runner-up',
  'IBM 2022 Research Accomplishment Award for Research Contributions to Watson Orchestrate',
  'IBM 2022 Annual Employee Individual Contributor Award',
  'IBM 2022 Entrepreneurship Award',
  'IBM 2021 Open Source Recognition Program Significant Contributor Award',
  'IBM 2021 Annual Employee Individual Contributor Award',
  'ICAPS 2021 Outstanding Program Committee Member Award',
  'AAAI 2021 Outstanding Senior Committee Member Award',
  'IBM 2020 Research Accomplishment Landmark for contributions to AI for Business Automation',
  'IBM 2020 Open Source Recognition Program Leadership Award',
  'ICAPS 2020 People’s Choice Best System Demonstration Award',
  "IEEE AI's 10 to Watch 2020",
  "IBM 2019 Individual Contributor Award for outstanding contributions and leadership in IBM's cognitive and cloud platform",
  'ASU CIDSE 2019 Ph.D. Student of the Year',
  'ICAPS 2019 Distinguished Dissertation Award (Honorable Mention)',
  'ICAPS 2018 People’s Choice Best System Demonstration Award Runner-up',
  'IBM Ph.D. Fellowship 2016-18',
  'Scholarship from Partnership of AI (PAI) to author the Landscaping Primer for the Pillar on Collaborations between People and AI',
  'Microsoft Imagine Cup 2017 US Finalist',
  'University Graduate Fellowship Award for outstanding research in Fall 2013, Spring 2014, Spring 2015, Spring 2017 and Spring 2018',
  'ICAPS 2014 People’s Choice Best System Demonstration Award',
];

export default DATA;
